import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  PropertyTypeId,
  useCreatePropertyType,
  useDeletePropertyType,
  useGetPropertyTypes,
  useUpdatePropertyType
} from '@api/endpoints/settings'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Box } from '@mui/system'
import { Button } from '@controls/Button'
import { Dictionary, DictionaryItem } from '@controls/DictionaryForm'

export const PropertyTypesPage: React.FC = () => {
  const { t } = useTranslation()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const getPropertyTypes = useGetPropertyTypes({ onError: defaultErrorHandler })
  const deletePropertyType = useDeletePropertyType({ onError: defaultErrorHandler })
  const createPropertyType = useCreatePropertyType({ onError: defaultErrorHandler })
  const updatePropertyType = useUpdatePropertyType({ onError: defaultErrorHandler })

  const loading =
    getPropertyTypes.isFetching ||
    deletePropertyType.isLoading ||
    createPropertyType.isLoading ||
    updatePropertyType.isLoading

  const tableData: ReadonlyArray<DictionaryItem<PropertyTypeId>> =
    getPropertyTypes.data?.map((row) => ({
      id: row.id,
      eng: row.title.eng,
      est: row.title.est,
      rus: row.title.rus,
      createdAt: row.createdAt
    })) ?? []

  return (
    <div>
      <h1>{t('pages.admin.property_types.title')}</h1>
      <Box display="flex" flexDirection="column" rowGap={4}>
        <Dictionary
          title={t('pages.admin.property_types.fields.title')}
          loading={loading}
          tableData={tableData}
          onDelete={deletePropertyType.mutate}
          onInsert={createPropertyType.mutate}
          onUpdate={updatePropertyType.mutate}
        />
      </Box>
      <Box display="flex" columnGap={2} mt={2}>
        <Button disabled={loading} onClick={() => getPropertyTypes.refetch()}>
          {t('global.buttons.refresh')}
        </Button>
      </Box>
    </div>
  )
}
